@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

$gridColumns: 40px 40px minmax(90px, 130px) 70px 50px 70px 80px 70px 70px 70px 90px 35px 35px;

.cntEdit {
  padding: 1.5rem;
  flex: 1;
  &.cntEdit__detail {
    background: map-get($colors, layout-white);
  }
  &_title {
    @include text(0.75rem, layout-grey-50);
    padding: 0 0 0.75rem;
  }
  .headerList {
    display: grid;
    grid-template-columns: $gridColumns;
    padding: 0 0 0.5rem;
    margin: 0;
    list-style: none;
    gap: 0.75rem;

    &_item {
      @include text(0.625rem, layout-grey-90, medium);
    }
  }
  .infoList {
    @extend .headerList;
    grid-template-columns: $gridColumns;
    padding: 0 0 0.75rem;
    gap: 0.75rem;

    &_vacancies {
      padding: 0 0 0 0.25rem;
    }

    &_item {
      @include text(0.875rem, layout-grey-90);
      text-transform: uppercase;
      .link {
        color: map-get($colors, darwined-blue-10);
        font-weight: map-get($fonts, medium);
        &_regular {
          font-weight: map-get($fonts, regular);
        }
        &:hover {
          color: map-get($colors, darwined-blue-30);
        }
        &:active {
          color: map-get($colors, darwined-blue-50);
        }
      }
      &.infoList_item__input {
        display: flex;
        .inputItem {
          flex: 1;
          max-width: 50px;
          max-height: 24px;
          font-size: 0.875rem;
          padding: 0.25rem 0.375rem;
          text-align: left;
        }
      }

      .disabledGroupBtn,
      .openGroup {
        @include flex(center, center);
        @include box(foris-white, 50%, darwined-blue-30);
        width: 20px;
        height: 20px;
        padding: 0;
        box-shadow: 0px 0px 2px 0px rgba(52, 58, 64, 0.5);
        cursor: pointer;
        outline: none;

        &__eye {
          @include box(layout-grey-10, 50%);
          box-shadow: none;
        }

        &.openGroup__closed {
          border-color: map-get($colors, layout-grey-50);
          .openGroup_icon {
            color: map-get($colors, layout-grey-50);
          }
          &:hover {
            border-color: map-get($colors, layout-grey-70);
            .openGroup_icon {
              color: map-get($colors, layout-grey-70);
            }
          }
          &:active {
            border-color: map-get($colors, layout-grey-90);
            .openGroup_icon {
              color: map-get($colors, layout-grey-90);
            }
          }
        }

        &.openGroup:disabled {
          background-color: transparent;
          border: none;

          .openGroup_icon {
            color: map-get($colors, layout-grey-70);
          }
        }

        &:hover {
          .disabledGroupBtn_icon,
          .openGroup_icon {
            color: map-get($colors, darwined-blue-50);
          }
        }
        &:active {
          box-shadow: 0px 0px 2px 0px rgba(52, 58, 64, 0.5);
          border-color: map-get($colors, darwined-blue-70);
          .disabledGroupBtn_icon,
          .openGroup_icon {
            color: map-get($colors, darwined-blue-70);
          }
        }
        &:disabled {
          @include box(layout-grey-30, 50%, layout-grey-30);
          box-shadow: none;
          pointer-events: none;
          .disabledGroupBtn_icon {
            color: map-get($colors, foris-white);
          }
        }
        &_icon {
          color: map-get($colors, darwined-blue-30);
        }
      }

      .migrateGroupBtn {
        transform: rotate(-45deg);
      }
    }
  }
  .totalList {
    @extend .headerList;
    padding: 0.75rem 0 0;
    border-top: 1px solid map-get($colors, layout-grey-10);
    display: grid;
    grid-template-columns: $gridColumns;

    .totalWrapper {
      grid-column-start: 5;
    }

    .actionWrapper {
      grid-column-start: 13;
    }

    &_item {
      @include text(0.875rem, layout-grey-70, bold);
      .totalInput {
        display: block;
        width: 50px;
        padding: 0 0.375rem;
        text-align: left;
      }
      .addGroup {
        @include flex(center, center);
        @include box(foris-white, 50%, darwined-blue-30);
        width: 20px;
        height: 20px;
        padding: 0;
        box-shadow: 0px 0px 2px 0px rgba(52, 58, 64, 0.5);
        cursor: pointer;
        outline: none;

        &:hover {
          border-color: map-get($colors, darwined-blue-50);
          .addGroup_icon {
            color: map-get($colors, darwined-blue-50);
          }
        }

        &:active {
          border-color: map-get($colors, darwined-blue-70);
          .addGroup_icon {
            color: map-get($colors, darwined-blue-70);
          }
        }

        &:disabled {
          @include box(layout-grey-30, 50%, layout-grey-30);
          box-shadow: none;
          pointer-events: none;
          .addGroup_icon {
            color: map-get($colors, foris-white);
          }
        }
        &_icon {
          color: map-get($colors, darwined-blue-30);
        }
      }
    }
  }
  .list_validation {
    list-style: none;
    padding: 1.5rem 0 0;
    &_item {
      @include text(0.875rem, message-alert-50);
      padding: 0.25rem 0 0;
      &:first-of-type {
        padding: 0;
      }
      &.list_validation_item__error {
        @include text(0.875rem, message-negative-50);
      }
    }
  }
}

.list_validation {
  list-style: none;
  padding: 1.5rem 0;
  gap: 0.25rem;

  &_item {
    @include text(0.875rem, message-alert-50);
    &:first-of-type {
      padding: 0;
    }
    &.list_validation_item__error {
      @include text(0.875rem, message-negative-50);
    }
  }
}

.addGroups {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: calc(100vh - 50px);
  display: block;
  background: map-get($colors, foris-white);
  .contentAddGroup {
    padding: 2rem 2rem 0 2rem;
    &_item {
      margin: 1.5rem 0 0;
      justify-content: space-between;
      &:first-child {
        margin: 0;
      }
      .itemLabel {
        @include text(0.75rem, layout-grey-90, medium);
        margin: 0 0 0.25rem;
      }
      &_selectors {
        width: 100%;
        max-width: 100%;
        padding: 0;
      }
      &_links {
        display: flex;
        align-items: center;
        align-self: flex-end;
        max-width: 100%;
        padding: 0 0 1.5rem 1.5rem;
        color: map-get($colors, darwined-blue-10);
        font-weight: map-get($fonts, medium);
        &_regular {
          font-weight: map-get($fonts, regular);
        }
        &:hover {
          color: map-get($colors, darwined-blue-30);
        }
        &:active {
          color: map-get($colors, darwined-blue-50);
        }
      }
    }
  }
  .footer {
    padding: 1.5rem 2rem 2rem;
    &_btn {
      margin: 0 0 0 0.875rem;
      padding: 0.625rem 1.5rem;
      &:first-of-type {
        margin: 0;
      }
    }
  }
}
