@import "@assets/scss/variables";
@import "@assets/scss/mixin";

.main {
  @include text(0.7rem, layout-grey-90);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1.5rem;

  .selectorGroup {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .packageName {
    max-width: 418px;
    margin-top: 1.5rem;
  }

  .selector {
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;

    &_control {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;

      .label {
        @include text(0.75rem, layout-grey-90, bold);
      }

      .term {
        width: 100%;
        max-width: 300px;
      }
    }

    &__input {
      margin-right: 1rem;
      min-width: 30%;
      padding: 0;
      &__population {
        width: 50%;
        max-width: 418px;

        &__label_top {
          @include text(0.75rem, layout-grey-90);
        }
        &__label_bottom {
          @include text(0.625rem, layout-grey-90);
        }
      }
      p {
        padding: 0;
      }
    }
    &__button {
      height: 36px;
      margin-right: 1rem;
      &_disabled {
        cursor: default;
        border: 1px solid map-get($colors, layout-grey-10);
        color: map-get($colors, layout-grey-40);
      }
      &:first-child {
        margin-right: 1rem;
      }
    }
  }

  .packageLabel {
    display: flex;
    flex-direction: column;
    text-align: left;

    &_mainLabel {
      @include text(0.75rem, layout-grey-70);
    }

    &_secondaryLabel {
      @include text(0.625rem, layout-grey-50);
    }
  }

  .buttons_section {
    width: 60%;
    border-top: 1px solid map-get($colors, layout-grey-20);
    padding-top: 1rem;
    margin-top: 1rem;
    display: flex;
  }

  .actions {
    width: 100%;
    margin-top: 1.5rem;
    display: flex;
  }

  .errorCard {
    margin-top: 1.5rem;
    box-shadow: 0px 1px 2px 1px rgba(52, 58, 64, 0.08), 0px 1px 6px 0px rgba(52, 58, 64, 0.2);
  }

  .packageLink {
    color: map-get($colors, darwined-blue-30);
  }
}
