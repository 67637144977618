@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.contentAddSection {
  padding-left: 0px;
  padding-bottom: 10px;
  &_item {
    margin: 0 0 20px;
    justify-content: space-between;
    > div {
      flex: 1;
      margin: 0 1rem 0 0;
      &:last-of-type {
        margin: 0;
      }
    }
    .itemLabel {
      @include text(12px, layout-grey-90, medium);
      margin: 0 0 4px;
    }
    &_selectors {
      width: 100%;
      max-width: 100%;
      padding: 0 !important;
    }
  }

  &_group {
    justify-content: flex-start;
  }
}

.footer {
  padding: 32px;
  padding-left: 0px;
  &_link {
    margin-right: 20px;
  }
  &_btn {
    margin: 0 0 0 14px;
    padding: 0.625rem 24px;
    &:first-of-type {
      margin: 0;
    }
  }
}
