@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.advanceSelector {
  display: flex;
  align-items: center;
  gap: map-get($spacing, sm);
  width: 100%;

  &_customField {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, xxs);
    flex: 1;
    max-width: 200px;

    .fieldLabel {
      font-weight: map-get($fonts, bold);
    }

    .fieldValue {
      display: flex;
      align-items: center;
      background-color: map-get($colors, layout-grey-10);
      border-radius: map-get($radius, md);
      font-size: map-get($sizeText, 14);
      color: map-get($colors, layout-grey-70);
      padding: map-get($spacing, xs);
      height: 36px;

      @media screen and (max-width: 992px) {
        height: 48px;
      }
    }
  }

  &_field {
    flex: 1;
    max-width: 220px;

    & > div {
      line-height: map-get($sizeText, 16);
    }
  }
}
