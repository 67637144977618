@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

$buttonHeight: 36px;
$buttonYPadding: 0.5rem;

.filter {
  display: flex;
  justify-content: flex-end;
  overflow: visible;
  width: 33.3%;
  gap: map-get($spacing, sm);

  .textSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    width: 250px;

    &_input {
      input {
        border-radius: 0;
        border-top-left-radius: map-get($radius, md);
        border-bottom-left-radius: map-get($radius, md);
      }
    }

    &_button {
      @include text(map-get($sizeText, 12), layout-grey-90, medium);
      align-items: center;
      background-color: map-get($colors, foris-white);
      border-bottom-right-radius: map-get($radius, md);
      border-top-right-radius: map-get($radius, md);
      border: 1px solid map-get($colors, layout-grey-30);
      border-left: none;
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: space-between;
      padding: map-get($spacing, xs) map-get($spacing, md);
      width: 125px;
      &:hover {
        background-color: map-get($colors, layout-grey-5);
      }
      &:active {
        background-color: map-get($colors, layout-grey-20);
      }
    }

    &_ghostButton {
      background-color: transparent;
    }
  }

  .columnsToSearchBy {
    align-items: flex-start;
    background-color: map-get($colors, foris-white);
    border-radius: map-get($spacing, md);
    box-shadow: 0px 0px 3px 2px rgba(52, 58, 64, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // `Filtrar` button's width + gap between `Filtrar` and `Buscar en`
    margin-right: calc(85.34px + map-get($spacing, sm));
    padding: map-get($spacing, sm);
    margin-top: calc(36px + map-get($spacing, xs));
    position: absolute;
    width: 250px;
    z-index: 2;

    &_container {
      width: 100%;
    }

    &_line {
      border: none;
      border-top: 1px solid map-get($colors, layout-grey-30);
    }
  }

  .columnsToHideConfig {
    align-items: flex-start;
    background-color: map-get($colors, foris-white);
    border-radius: map-get($radius, md);
    box-shadow: 0px 0px 3px 2px rgba(52, 58, 64, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: calc(36px + map-get($spacing, xs));
    padding: map-get($spacing, xs);
    position: absolute;
    width: 346px;
    z-index: 2;

    &_header {
      @include text(map-get($sizeText, 16), layout-grey-90, medium);
      margin-bottom: 0.875rem;
    }
    &_line {
      border: none;
      border-top: 1px solid map-get($colors, layout-grey-30);
      width: 100%;
    }

    .columns {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, sm);
      &_header {
        @include text(map-get($sizeText, 12), layout-grey-70, medium);
        margin-bottom: map-get($spacing, md);
      }
      &_column {
        @include text(map-get($sizeText, 16), layout-grey-90, regular);
        &:last-child {
          margin-bottom: map-get($spacing, md);
        }
      }
    }

    .columnToOrderBy {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      &_header {
        @include text(map-get($sizeText, 12), layout-grey-70, medium);
      }
      &_selector {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
      }

      .selector {
        &_select {
          width: 80%;
        }
        &_directionButton {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          height: 36px;
          width: 36px;
          background-color: transparent;
          cursor: pointer;
          &:hover {
            background-color: map-get($colors, layout-grey-5);
          }
          &:active {
            background-color: map-get($colors, layout-grey-20);
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: map-get($spacing, lg);
    gap: map-get($spacing, sm);
    width: 100%;
    &_button {
      width: 95%;
    }
  }
}
