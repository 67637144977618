@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/legacyBreakpoints";
@import "../../../../../assets/scss/mixin";

.main {
  margin: 0.875rem 0 0 0;
  width: 83.3%;
  border: 1px solid map-get($colors, layout-grey-10);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
}

.card {
  display: flex;
  background-color: map-get($colors, foris-white);
  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    &__title {
      @include text(map-get($sizeText, 16), layout-grey-90, bold);
      margin-bottom: 0.5rem;
    }
    &__subtitle {
      @include text(map-get($sizeText, 14), layout-grey-90);
      padding: map-get($spacing, md) 0;
    }
  }
  &__campuses {
    &__item {
      margin-top: 0.4rem;
    }
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    &__label {
      @include text(map-get($sizeText, 14), layout-grey-90, bold);
      padding: map-get($spacing, lg) 0;
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      gap: map-get($spacing, md);
    }
  }
}

@media (max-width: map-get($legacyBreakpoints, xl)) {
  .main {
    width: 100% !important;
  }
}
