@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.cntAddLink {
  &_title {
    @include text(16px, layout-grey-90, bold);
    margin: 0;
    padding: 0 0 16px;
  }
  .cntOption {
    padding: 0 0 20px;
    &_item {
      &:first-of-type {
        margin: 0;
      }
      margin: 0 0 0 12px;
    }
  }
  .cntCreateLink {
    &_title {
      @include text(16px, layout-grey-90, bold);
      margin: 0;
      padding: 0 0 16px;
    }
    .listComponent {
      list-style: none;
      padding: 0;
      margin: 0;
      &_item {
        &:first-of-type {
          .componentType {
            border-bottom: 1px solid map-get($colors, darwined-blue-50);
            padding: 12px 0;
          }
        }
        &:last-of-type {
          .componentType {
            margin: 0;
            padding: 12px 0 0;
            border-bottom: none;
          }
        }
        .componentType {
          max-width: 470px;
          min-width: 470px;
          padding: 12px 0;
          border-bottom: 1px solid map-get($colors, darwined-blue-50);

          &_title {
            @include text(14px, darwined-blue-50, medium);
            min-width: 125px;
            margin: 0 0 8px;
          }
          &_option {
            &:first-of-type {
              margin: 0;
            }
            margin: 0 0 0 12px;
          }
          &_select {
            padding: 12px 0 0;
          }
        }
      }
    }
    .validationItem {
      padding: 0 0 12px;
    }
    .footer {
      &_btn {
        &:first-of-type {
          margin: 0;
        }
        margin: 0 0 0 12px;
      }
    }
  }
  .cntEmpty {
    @include text(14px, darwined-blue-50, bold);
    margin: 0;
    padding: 0 0 16px;
  }
}
