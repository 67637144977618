@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/variables";

.content {
  padding: 0.75rem 0 0;
  .item {
    @include flex(space-between, center);
    padding: 0 0 0.75rem;
    &_select {
      flex-grow: 1;
    }
    &_link {
      @include text(0.875rem, darwined-blue-10);
      padding: 0 0 0 1.5rem;
      cursor: pointer;
      outline: none;
      border: none;
      background: none;
      &:hover {
        color: map-get($colors, darwined-blue-30);
      }
      &:active {
        color: map-get($colors, darwined-blue-50);
      }
      &:disabled {
        color: map-get($colors, layout-grey-30);
        pointer-events: none;
      }
    }
  }

  & div[class*="reactSelect__option--is-disabled"] {
    background-color: var(--color-neutral-00) !important;
  }
}
